h1,
ol {
  margin-block-start: 0;
  margin-block-end: 0;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent; 
}

body {
  background-color: #523bff;
}

.App-Content {
  background-color: #523bff;
}

.App {
  text-align: center;
  margin: 15px;
}

.App-header {
}

.App-logo {
}

.App-link {
  color: #61dafb;
}

.App-Content {
  text-align: center;
}

.App-Component {
  padding: 2vh 2vw;
  color: #ffffff;
}

.Form {
  min-width: 300px;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  border-radius: 20px;
  border: 1px solid #000000;
  background-color: #ffffff;
  color: #000000;
}

.Form-Header {
}

.Form-Header .Bold {
  color: #523bff;
}

.Form-Placeholder {
  margin: 30px 0 0 0;
}

.Form-Input {
  min-width: 300px;
  margin-top: -10px;
  padding: 10px 10px;
  font-size: 20px;
}

.Form-Button {
  padding: 15px 20px;
  color: #ffffff;
  font-weight: bold;
  background-color: #000000;
}

.Form-Tooltip {
  margin-top: 20px;
  margin-left: 4px;
  font-size: 0.9em;
}

.hidden {
  display: none;
}

.loader {
  text-align: center;
}

.loader::after {
  content: "...";
  animation: dots 1s infinite;
}

@keyframes dots {
  0% {
    content: "";
  }
  30% {
    content: ".";
  }
  53% {
    content: "..";
  }
  66% {
    content: "...";
  }
}

.flinksconnect {
  width: 100%;
  border: 0;
}

.Notes {
  padding: 10px 30px;
  text-align: left;
  color: #ffffff;
}

.Notes li {
  line-height: 27px;
  font-size: 20px;
  margin: 15px 0;
}

.Notes .question {
  font-weight: bold;
}

.Menu {
  position: fixed;
  top: 0;
  left: -200px;
  width: 200px;
  height: 100%;
  background-color: #fff;
  transition: transform 0.3s ease-in;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(0);
}

.Menu.open {
  transform: translateX(100%);
}

.Menu-list {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 1vh;
}

.Menu-item {
  padding-left: 1vw;
}

.Menu-item:hover {
  background-color: #000000;
}

.Menu-item a {
  color: #000000;
  display: block;
  padding: 2vh 0;
  font-size: 1.5em;
  padding-left: 2vh;
  font-weight: bold;
  text-decoration: none;
}

.Menu-item a:hover {
  color: #ffffff;
  text-decoration: none;
}

.Menu-item a.active {
  color: #000000;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
}
