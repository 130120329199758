.container
{
    display: flex;
    flex-direction: row;
    width: 270px;
    align-self: flex-start;
    margin-left: 10px;
    margin-top: 20px;
}

.image
{
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background-color: #E4E4E4;
}

.messageView
{
    background-color: blue;
    margin-left: 5px;
    border-radius: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.messageText
{
    font-size: 16px;
    color: white;
}

.indicator
{
    margin-top: auto;
    margin-bottom: auto;
}