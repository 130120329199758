.down
{
    position: relative;
}

.down:after
{
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0;
    height: 20px;
    width: 100%;
    border-top: solid 2px #6490e2;
    border-top-color: #f4a804;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 64%;
}
