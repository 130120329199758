h1,
ol {
  margin-block-start: 0;
  margin-block-end: 0;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.App-Content {
  background-color: #523bff;
}

.App {
  text-align: center;
  margin: 15px;
}

.App-header {}

.App-logo {}

.App-button {
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.App-link {
  color: #61dafb;
}

.App-Content {
  text-align: center;
}

.App-Component {
  padding: 2vh 2vw;
  color: #ffffff;
}

.Form {
  min-width: 300px;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  border-radius: 20px;
  border: 1px solid #000000;
  background-color: #ffffff;
  color: #000000;
}

.Form-Header {}

.Form-Header .Bold {
  color: #523bff;
}

.Form-Placeholder {
  margin: 30px 0 0 0;
}

.Form-Input {
  height: 50px !important;
  font-size: 20px !important;
}

.Form-Input .flag {
  width: 32px !important;
}

.Form-Button {
  padding: 15px 20px;
  color: #ffffff;
  font-weight: bold;
  background-color: #000000;
}

.Form-Tooltip {
  margin-top: 20px;
  margin-left: 4px;
  font-size: 0.9em;
}

.hidden {
  display: none;
}

.loader {
  text-align: center;
}

.loader::after {
  content: "...";
  animation: dots 1s infinite;
}

@keyframes dots {
  0% {
    content: "";
  }

  30% {
    content: ".";
  }

  53% {
    content: "..";
  }

  66% {
    content: "...";
  }
}

.flinksconnect {
  width: 100%;
  border: 0;
}

.Notes {
  padding: 10px 30px;
  text-align: left;
  color: #ffffff;
}

.Notes li {
  line-height: 27px;
  font-size: 20px;
  margin: 15px 0;
}

.Notes .question {
  font-weight: bold;
}

.App-Tooltip {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eeeeee;
  color: #000000;
  font-size: 0.9em;
  border-radius: 10px 10px 0 0;
  line-height: 1.2em;
  text-align: center;
  width: 275px;
  margin: 0 auto;
  opacity: 0;
}

.App-Tooltip button {
  padding: 10px;
  border: 0;
  background-color: #cccccc;
}

/* Define the animation */
@keyframes slideUpAnimation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 0.95;
  }
}

/* Apply the animation to the tooltip */
.App-Tooltip.active {
  /* Add the class "active" to trigger the animation */
  animation: slideUpAnimation 0.5s ease forwards;
}