.container
{
    width: 250px;
    align-self: flex-end;
    margin-right: 15px;
    display: flex;
    /* Added to allow alignItems to work */
    align-items: flex-end;
    margin-top: 10px;
}

.messageView
{
    background-color: black;
    margin-left: 10px;
    border-radius: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.messageText
{
    font-size: 16px;
    color: white;
}