.container {
  display: flex;
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.right-column {
  flex: 1;
  padding: 20px;
}

#notes {
  margin: 30px 40px;
  text-align: left;
}

#notes ul li {
  line-height: 27px;
}

#dropArea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 200px;
  border: 1px dashed black;
  margin: 0 auto;
}
